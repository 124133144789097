<template>
    <v-card class="my-3">
        <v-card-title
            class="primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
            >Rental History</v-card-title
        >

        <v-card-text>
            <v-timeline align-top dense>
                <template v-for="(address, index) in addressItems">
                    <div class="page" style="margin-top: 80px" :key="index">
                        <v-timeline-item
                            icon="mdi-map-marker"
                            :color="address.color"
                        >
                            <v-card :color="address.color" dark>
                                <v-card-title
                                    class="text-subtitle-2 text-uppercase font-weight-bold py-1"
                                    style="height: 40px"
                                >
                                    <v-icon class="mr-1">{{
                                        address.icon
                                    }}</v-icon>
                                    {{ address.type }} ADDRESS
                                    <v-spacer></v-spacer>
                                    <v-icon>mdi-check-circle</v-icon>
                                </v-card-title>
                                <v-card-text
                                    class="white text--primary pt-2 text-subtitle-2"
                                >
                                    <div class="d-flex">
                                        <rental-history-address
                                            :address="address"
                                        >
                                            <template v-slot:indicators>
                                                <div class="d-flex flex-column">
                                                    <v-chip
                                                        class="text-subtitle-2 text-uppercase font-weight-bold"
                                                        :color="
                                                            address.labelColor
                                                        "
                                                        >{{
                                                            address.status
                                                        }}</v-chip
                                                    >

                                                    <v-chip
                                                        class="text-subtitle-2 text-uppercase font-weight-bold mt-1"
                                                        :color="
                                                            address.actionsLabel
                                                        "
                                                        >{{
                                                            $dollarFormat(
                                                                address.monthly_payment
                                                            )
                                                        }}
                                                        / month</v-chip
                                                    >
                                                </div>
                                            </template>
                                        </rental-history-address>
                                        <v-spacer></v-spacer>
                                        <div>
                                            <template
                                                v-for="(
                                                    reference, index
                                                ) in address.references"
                                            >
                                                <rental-history-reference
                                                    :key="index"
                                                    :reference="reference"
                                                ></rental-history-reference>
                                            </template>
                                        </div>
                                    </div>
                                    <div>
                                        <v-divider
                                            class="grey lighten-2 my-3"
                                        ></v-divider>
                                        <h4 class="mb-2">Address Notes</h4>
                                        <template
                                            v-for="(
                                                note, index
                                            ) in address.notes"
                                        >
                                            <div :key="index">
                                                <span
                                                    ><v-icon
                                                        :class="`${address.color}--text mr-1`"
                                                        >mdi-calendar</v-icon
                                                    >{{ note.created_at }}
                                                    <v-icon
                                                        :class="`${address.color}--text mr-1`"
                                                        >mdi-account</v-icon
                                                    >{{ note.user.name }}</span
                                                >

                                                <p
                                                    v-html="note.note"
                                                    class="mt-1"
                                                ></p>
                                                <v-divider
                                                    v-if="
                                                        index <
                                                        address.notes.length - 1
                                                    "
                                                    class="grey lighten-2 my-1"
                                                ></v-divider>
                                            </div>
                                        </template>
                                    </div>
                                </v-card-text>
                                <v-card-actions :class="address.actionsColor">
                                    <v-spacer></v-spacer>
                                    <v-chip
                                        class="mr-3 text-subtitle-2 text-uppercase font-weight-bold"
                                        :disabled="address.flags.warning === 0"
                                        :class="{
                                            'grey--text text--darken-4':
                                                address.flags.warning === 0,
                                        }"
                                        :color="
                                            address.flags.warning === 0
                                                ? 'grey lighten-2'
                                                : 'warning darken-2'
                                        "
                                        >Warnings:
                                        {{ address.flags.warning }}</v-chip
                                    >
                                    <v-chip
                                        class="mr-3 text-subtitle-2 text-uppercase font-weight-bold"
                                        :disabled="address.flags.critical === 0"
                                        :class="{
                                            'grey--text text--darken-4':
                                                address.flags.critical === 0,
                                        }"
                                        :color="
                                            address.flags.critical === 0
                                                ? 'grey lighten-2'
                                                : 'error darken-2'
                                        "
                                        >Critical:
                                        {{ address.flags.critical }}</v-chip
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-timeline-item>
                    </div>
                </template>
            </v-timeline>
        </v-card-text>
    </v-card>
</template>
<script>
import dayjs from "dayjs";
export default {
    name: "rental-history",
    components: {
        RentalHistoryAddress: () => import("./RentalHistoryAddress"),
        RentalHistoryReference: () => import("./RentalHistoryReference"),
    },
    props: {
        addresses: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        addressItems() {
            return this.addresses
                .slice()
                .sort((a, b) => {
                    return a.type > b.type;
                })
                .map((item) => {
                    const {
                        color,
                        actionsColor,
                        durationLabel,
                        actionsLabel,
                        labelColor,
                    } = this.getItemColors(item.type);

                    const duration = this.getDuration(
                        item.move_out_date,
                        item.move_in_date
                    );
                    const flags = this.getReferenceFlags(item.references);
                    const icon = this.getIcon(item.type);

                    item = Object.assign(item, {
                        color,
                        actionsColor,
                        labelColor,
                        durationLabel,
                        actionsLabel,
                        duration,
                        flags,
                        icon,
                    });

                    return item;
                });
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        getItemColors(type) {
            switch (type) {
                case "Current":
                    return {
                        color: "primary",
                        actionsColor: "primary",
                        actionsLabel: "primary darken-2",
                        durationLabel: "primary",
                        labelColor: "primary darken-2",
                    };
                case "Previous":
                    return {
                        color: "accent",
                        actionsColor: "accent",
                        actionsLabel: "accent darken-2",
                        durationLabel: "accent",
                        labelColor: "accent darken-2",
                    };
            }
        },
        getDuration(move_out_date, move_in_date) {
            move_out_date === move_out_date ? move_out_date : Date.now();
            const date1 = dayjs(move_out_date);
            const date2 = dayjs(move_in_date);
            return `${date1.diff(date2, "month").toString()} months`;
        },
        getIcon(type) {
            return type === "Current"
                ? "mdi-calendar-cursor"
                : "mdi-calendar-clock";
        },
        getReferenceFlags(references) {
            let flags = {
                warning: 0,
                critical: 0,
            };
            references.forEach((item) => {
                item.flags.forEach((flag) => {
                    flags[flag.severity]++;
                });
            });
            return flags;
        },
    },
};
</script>

<style scoped>
</style>