var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-3" },
    [
      _c(
        "v-card-title",
        {
          staticClass:
            "primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
        },
        [_vm._v("Rental History")]
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-timeline",
            { attrs: { "align-top": "", dense: "" } },
            [
              _vm._l(_vm.addressItems, function(address, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "page",
                      staticStyle: { "margin-top": "80px" }
                    },
                    [
                      _c(
                        "v-timeline-item",
                        {
                          attrs: {
                            icon: "mdi-map-marker",
                            color: address.color
                          }
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { color: address.color, dark: "" } },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "text-subtitle-2 text-uppercase font-weight-bold py-1",
                                  staticStyle: { height: "40px" }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-1" }, [
                                    _vm._v(_vm._s(address.icon))
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(address.type) + " ADDRESS "
                                  ),
                                  _c("v-spacer"),
                                  _c("v-icon", [_vm._v("mdi-check-circle")])
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "white text--primary pt-2 text-subtitle-2"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("rental-history-address", {
                                        attrs: { address: address },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "indicators",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column"
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "text-subtitle-2 text-uppercase font-weight-bold",
                                                          attrs: {
                                                            color:
                                                              address.labelColor
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              address.status
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "text-subtitle-2 text-uppercase font-weight-bold mt-1",
                                                          attrs: {
                                                            color:
                                                              address.actionsLabel
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$dollarFormat(
                                                                address.monthly_payment
                                                              )
                                                            ) + " / month"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("v-spacer"),
                                      _c(
                                        "div",
                                        [
                                          _vm._l(address.references, function(
                                            reference,
                                            index
                                          ) {
                                            return [
                                              _c("rental-history-reference", {
                                                key: index,
                                                attrs: { reference: reference }
                                              })
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("v-divider", {
                                        staticClass: "grey lighten-2 my-3"
                                      }),
                                      _c("h4", { staticClass: "mb-2" }, [
                                        _vm._v("Address Notes")
                                      ]),
                                      _vm._l(address.notes, function(
                                        note,
                                        index
                                      ) {
                                        return [
                                          _c(
                                            "div",
                                            { key: index },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      class:
                                                        address.color +
                                                        "--text mr-1"
                                                    },
                                                    [_vm._v("mdi-calendar")]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(note.created_at) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      class:
                                                        address.color +
                                                        "--text mr-1"
                                                    },
                                                    [_vm._v("mdi-account")]
                                                  ),
                                                  _vm._v(_vm._s(note.user.name))
                                                ],
                                                1
                                              ),
                                              _c("p", {
                                                staticClass: "mt-1",
                                                domProps: {
                                                  innerHTML: _vm._s(note.note)
                                                }
                                              }),
                                              index < address.notes.length - 1
                                                ? _c("v-divider", {
                                                    staticClass:
                                                      "grey lighten-2 my-1"
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ]
                              ),
                              _c(
                                "v-card-actions",
                                { class: address.actionsColor },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass:
                                        "mr-3 text-subtitle-2 text-uppercase font-weight-bold",
                                      class: {
                                        "grey--text text--darken-4":
                                          address.flags.warning === 0
                                      },
                                      attrs: {
                                        disabled: address.flags.warning === 0,
                                        color:
                                          address.flags.warning === 0
                                            ? "grey lighten-2"
                                            : "warning darken-2"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "Warnings: " +
                                          _vm._s(address.flags.warning)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass:
                                        "mr-3 text-subtitle-2 text-uppercase font-weight-bold",
                                      class: {
                                        "grey--text text--darken-4":
                                          address.flags.critical === 0
                                      },
                                      attrs: {
                                        disabled: address.flags.critical === 0,
                                        color:
                                          address.flags.critical === 0
                                            ? "grey lighten-2"
                                            : "error darken-2"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "Critical: " +
                                          _vm._s(address.flags.critical)
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }